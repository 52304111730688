<template>
    <section class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 191px)">
        <div class="row mx-0 px-5 pt-4">
            <div class="col-4">
                <div class="bg-light-f5 br-5 d-middle text-general py-1 px-2">
                    <span class="f-17">
                        Entregas en proceso
                    </span>
                    <span class="ml-auto f-17 f-600">
                        {{ separadorNumero(sumV) }}
                    </span>
                    <div class="bg-general rounded-circle text-white d-middle-center ml-2" style="width:30px;height:30px;">
                        {{ vigentes.length }}
                    </div>
                </div>
            </div>
            <div class="col-auto ml-auto">
                <el-popover
                placement="bottom"
                width="258"
                trigger="hover"
                >
                    <div class="row cr-pointer mx-0 align-items-center mb-1 item" @click="entregarDineroALeeche(1)">
                        <i class="icon-comision text-general f-18" />
                        <div class="col text-general f-15">
                            Pagar en efectivo
                        </div>
                    </div>
                    <div class="row cr-pointer mx-0 align-items-center mb-1 item" @click="entregarDineroALeeche(2)">
                        <i class="icon-transferencia_bancaria text-general f-18" />
                        <div class="col text-general f-15">
                            Transferencia bancaria
                        </div>
                    </div>
                    <div class="row cr-pointer mx-0 align-items-center item" @click="entregarDineroALeeche(3)">
                        <i class="icon-creditos text-general f-18" />
                        <div class="col text-general f-15">
                            Pago en línea
                        </div>
                    </div>

                    <div slot="reference" class="bg-general py-1 px-3 d-middle-center br-5 text-white cr-pointer" style="height:32px;" >
                        Entrega de dinero a leeche <i class="icon-angle-down f-20 text-general text-white" />
                    </div>
                </el-popover>
            </div>
        </div>
        <div v-for="(data, e) in vigentes" :key="e" class="row mx-0 px-5 mt-3 cr-pointer" @click="detalleEntrega(data)">
            <div class="col-12 ml-3 border py-2 br-5 d-middle">
                <div class="col">
                    <div class="row mx-0">
                        <i class="icon-comision text-general f-14" />
                        <div class="col-2 text-general f-17">
                            {{ data.tipo == 1 ? 'Efectivo': (data.tipo == 2 ? 'Transferencia' : 'Pago en línea') }}
                        </div>
                        <div class="col-auto text-general">
                            {{ separadorNumero(data.valor) }}
                        </div>
                        <div class="col-auto text-general">
                            {{ formatearFecha(data.created_at, 'D MMM Y hh:mm a') }}
                        </div>
                        <div class="col f-15 text-general f-600">
                            <template v-if="data.tipo == 3">
                                Ref. {{ data.pasarela_payu.transaction_id }}
                            </template>
                            <template v-else>
                                {{ data.codigo }}
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-5 pr-0 d-middle border-left h-100">
                    <template v-if="data.estado == 2">
                        <img :src="data.delivery_imagen" width="36px" height="36px" class="obj-cover rounded-circle" />
                        <i class="icon-entrega-dinero text-success f-17" />
                        <div class="col-auto text-general f-600">
                            Recibido
                        </div>
                        <div class="col text-general">
                            {{ formatearFecha(data.fecha_recibio, 'D MMM Y hh:mm a') }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="row mx-0 px-5 my-4" />
        <div class="row mx-0 px-5">
            <div class="col-4">
                <div class="bg-light-f5 br-5 d-middle text-general py-1 px-2">
                    <span class="f-17">
                        Entregas finalizadas
                    </span>
                    <span class="ml-auto f-17 f-600">
                        {{ separadorNumero(sum) }}
                    </span>
                </div>
            </div>
        </div>
        <div v-for="(data, k) in finalizadas" :key="`d-${k}`" class="row mx-0 px-5 mt-3 cr-pointer" @click="detalleEntrega(data)">
            <div class="col-12 ml-3 border py-2 br-5 d-middle">
                <div class="col">
                    <div class="row mx-0">
                        <i class="icon-comision text-general f-14" />
                        <div class="col-2 text-general f-17">
                            {{ data.tipo == 1 ? 'Efectivo': (data.tipo == 2 ? 'Transferencia' : 'Pago en línea') }}
                        </div>
                        <div class="col-auto text-general">
                            {{ separadorNumero(data.valor) }}
                        </div>
                        <div class="col-auto text-general">
                            {{ formatearFecha(data.created_at, 'D MMM Y hh:mm a') }}
                        </div>
                        <div class="col f-15 text-general f-600">
                            <template v-if="data.tipo == 3">
                                Ref. {{ data.pasarela_payu.transaction_id }}
                            </template>
                            <template v-else>
                                {{ data.codigo }}
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-5 pr-0 d-middle border-left">
                    <img :src="data.estado == 4 ? data.tienda_imagen : '/img/icons/bell.png'" width="36px" height="36px" class="obj-cover rounded-circle" />
                    <i class="f-22" :class="data.estado == 3 ? 'icon-account-check text-success' : 'icon-playlist-remove text-danger'" />
                    <div class="col-auto text-general f-600">
                        {{ data.estado == 3 ? 'Recibido': 'Anulado' }}
                    </div>
                    <div class="col text-general">
                        {{ formatearFecha(data.fecha_confirmo, 'D MMM Y hh:mm a') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center text-center">
            <div class="col-3">
                <div class="row mx-0">
                    <div class="bg-general3 text-white br-20 px-2 py-1 mx-1 cr-pointer" @click="listarFinalizadas(10)">
                        Ver más 10
                    </div>
                    <div class="bg-general3 text-white br-20 px-2 py-1 mx-1 cr-pointer" @click="listarFinalizadas(50)">
                        Ver más 50
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-entrega-dinero ref="modalEntregaDinero" @saved="listarEntregas()" />
        <modal-detalle-entrega ref="modalDetalleEntrega" @saved="listarEntregas()" @closed="init()" />
        <modal-respuesta-pago-payu ref="modalRespuestaTransaccion" />
    </section>
</template>

<script>
import entregasDineros from '~/services/almacen/entregas_dineros'
export default {
    components: {
        modalEntregaDinero: () => import('./partials/modalEntregaDinero.vue'),
        modalDetalleEntrega: () => import('./partials/modalDetalleEntrega.vue'),
    },
    data(){
        return {
            url_image: 'httpxs://img.freepik.com/foto-gratis/retrato-joven-sonriente-gafas_171337-4842.jpg?size=626&ext=jpg',
            vigentes: [],
            finalizadas: [],
            sum: ''
        }
    },
    computed: {
        sumV(){
            if(!this.vigentes.length) return ''
            return _.sumBy(this.vigentes, 'valor')
        }
    },
    async mounted(){
        if(this.$route.query.payu == 1){
            this.$refs.modalRespuestaTransaccion.toggle()
        }
        await this.init()
    },
    methods: {
        entregarDineroALeeche(tipoEntrega){
            this.$refs.modalEntregaDinero.toggle(tipoEntrega);
        },
        detalleEntrega({ id }){
            this.$refs.modalDetalleEntrega.toggle(id);
        },
        async init(){
            await this.listarEntregas()
            await this.listarFinalizadas()
        },
        async listarEntregas(){
            try {
                const { data } = await entregasDineros.listarEntregasWebLeechero()
                this.vigentes = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async listarFinalizadas(paginate = 10){
            try {
                const { id: lastId = null } = _.last(this.finalizadas) ?? {}
                const params = {
                    paginate,
                    cedi: this.id_cedis,
                    lastId
                }
                const { data } = await entregasDineros.listarEntregasFinalizadasWebLeechero(params)
                this.finalizadas = [...this.finalizadas, ...data.data]
                this.sum = data.sum
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>

</style>
